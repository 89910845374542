import {
  addErrorHandler,
  checkActivityFunctions,
  getAppStatus,
  navigateToUrl,
  SingleSpaCustomEventDetail,
  start,
  triggerAppChange,
} from 'single-spa';

import {
  bootstrap,
  MicrofrontendConfiguration,
  JSONObject,
} from '@fnz-dock/core';

import { updateBreadcrumbs } from './utils/breadcrumbs';
import { addLoadingUI, removeLoadingUI } from './utils/loading-ui';
import { isMountedMFEMatchedWithLocation } from './utils/mfe-matched-location';
import { processMicrofrontends } from './utils/register';
import { waitForElement } from './utils/wait-for-element';

export const init = async (
  configuration: JSONObject,
  extend: JSONObject = {},
) => {
  const { loadingStyle } = configuration;
  addLoadingUI(loadingStyle);
  const dockConfiguration = await bootstrap({ configuration }, extend);
  let navigatorMounted = false;
  let navigatorRendered = false;
  const isLayoutReady = (
    configuration: MicrofrontendConfiguration,
    navigatorRenderDependant = false,
  ) => {
    if (configuration.id === '@fnz-dock/navigator') {
      return true;
    }
    if (navigatorRenderDependant) {
      return navigatorRendered;
    }
    return navigatorMounted;
  };

  await processMicrofrontends(dockConfiguration.microfrontends, isLayoutReady);
  const firstMFELink = dockConfiguration.navigatorConfiguration.links.find(
    (link) => 'path' in link,
  );
  const errorMFE = dockConfiguration.microfrontends.find(
    (mfe) => mfe.id === '@fnz-dock/error-page',
  );
  // Once the navigator is mounted, we will trigger a change to mount product application
  window.addEventListener('single-spa:first-mount', () => {
    // We are waiting for topbar to render, but if it is not in 700ms we are considering that it has been rendered anyway
    const waitTimeout = 700;
    waitForElement(
      '.dock-mfe-application-wrapper[data-mfe-id="mfe--fnz-dock-navigator"] .dl-topbar-actions',
      waitTimeout,
      true,
    ).then(() => {
      navigatorRendered = true;
      navigatorMounted = true;
      triggerAppChange();
      removeLoadingUI();
    });
  });
  window.addEventListener(
    'single-spa:before-mount-routing-event',
    (evt: CustomEvent<SingleSpaCustomEventDetail>) => {
      updateBreadcrumbs(evt, dockConfiguration);
    },
  );
  addErrorHandler((err) => {
    console.log(err);
    console.log(err.appOrParcelName);
    console.log(getAppStatus(err.appOrParcelName));
    if (firstMFELink && 'path' in firstMFELink) {
      navigateToUrl(firstMFELink.path);
    }
  });
  window.addEventListener('single-spa:routing-event', () => {
    const activeApps = checkActivityFunctions(location);

    // ignore main page
    if (
      location.pathname !== '/' &&
      !isMountedMFEMatchedWithLocation(
        activeApps,
        dockConfiguration.microfrontends,
      ) &&
      !dockConfiguration.legacySSR
    ) {
      // have that logic only when dock is completely ready
      if (navigatorMounted) {
        const authUrl =
          (dockConfiguration.authConfiguration.type === 'oidc' &&
            dockConfiguration.authConfiguration.settings?.redirect_uri) ||
          '';
        if (authUrl && window.location.href.startsWith(authUrl)) {
          if (firstMFELink && 'path' in firstMFELink) {
            navigateToUrl(firstMFELink.path);
          }
        } else {
          if (errorMFE && 'path' in errorMFE) {
            navigateToUrl(errorMFE.path);
          }
        }
      }
    }
  });
  start({ urlRerouteOnly: true });
};
